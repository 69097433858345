export default defineComponent({
  props: {
    disabled: Boolean,
    options: Array
  },
  setup: function setup() {
    return {
      value: null
    };
  },
  methods: {
    handleChangeValue: function handleChangeValue(value) {
      console.log(value);
      this.value = value;
    }
  }
});